/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {PDFViewer} from "components";
import {
  Button,
  Modal,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Switch,
  Tooltip,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Link,
  Grid,
} from "@material-ui/core";
import {
  Payment,
  Publish,
  Refresh,
  Receipt,
  Send,
  LocalOffer,
  GetApp,
} from "@material-ui/icons";
import {RetrieveUserInformation} from "services/Auth/usermanager.service";
import {requestValeuReview} from "services/Finance/finance.service";

import Uploader from "../Uploader";
import moment from "moment";
import NumberFormat from "react-number-format";

import "./FinancialItem.scss";
import RequestRevision from "./RequestRevision";

import {getArchiveDocumentsNew} from "services/Process/process.service";

const FinancialItem = (props) => {
  const {
    refItem,
    propsItem: financialData,
    documents,
    adRefItem,
    showOnlyPayedItems,
  } = props;
  const [responseMessage, setResponseMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [available, setAvailable] = React.useState(false);
  const [checked, setChecked] = React.useState(
    financialData.isChecked ? financialData.isChecked : false,
  );
  const [urlPaymentSlip, setUrlPaymentSlip] = React.useState("");
  const [additionalReference, setAdditionalReference] = React.useState("");
  const [show, setShow] = React.useState({
    preview: false,
    response: false,
    receipt: false,
    upload: false,
    additionalReference: false,
    request: false,
  });

  const getDocumentInvoice = async (item) => {
    try {
      const response = await getArchiveDocumentsNew(item.guid);

      const link = document.createElement("a");
      link.href = response.data.url;
      link.download = `${item.fileName}`;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
    } catch (err) {
      console.log(err);
    }
  };

  const getDocumentPayment = async (item) => {
    try {
      if (documents.length > 0) {
        let paymentSlips = documents.filter((i) => {
          return i.group === "BOLETO";
        });

        if (paymentSlips.length > 0 && paymentSlips.length === 1) {
          if (
            paymentSlips[0].guid !== null ||
            paymentSlips[0].guid !== undefined
          ) {
            const response = await getArchiveDocumentsNew(paymentSlips[0].guid);
            const link = document.createElement("a");
            link.href = response.data.url;
            link.download = `${paymentSlips[0].fileName}`;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link.remove();
          } else {
            const link = document.createElement("a");
            link.href = paymentSlips[0].url;
            link.download = `${paymentSlips[0].fileName}`;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link.remove();
          }
        } else {
          if (
            paymentSlips[0].guid !== null ||
            paymentSlips[0].guid !== undefined
          ) {
            const response = await getArchiveDocumentsNew(
              paymentSlips[paymentSlips.length - 1].guid,
            );
            const link = document.createElement("a");
            link.href = response.data.url;
            link.download = `${paymentSlips[paymentSlips.length - 1].fileName}`;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link.remove();
          } else {
            const link = document.createElement("a");
            link.href = paymentSlips[paymentSlips.length - 1].url;
            link.download = `${paymentSlips[paymentSlips.length - 1].fileName}`;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link.remove();
          }
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    setAvailable(financialData.isAvailable ? financialData.isAvailable : false);
  }, [financialData]);

  useEffect(() => {
    if (adRefItem) setAdditionalReference(adRefItem);

    // if (documents.length > 0) {
    //   let paymentSlips = documents.filter((i) => {
    //     return i.group === "BOLETO";
    //   });
    //   if (paymentSlips.length > 0 && paymentSlips.length === 1) {
    //     if (paymentSlips[0].guid !== null) {
    //       const response = getAzureDocuments(paymentSlips[0].guid);
    //       setUrlPaymentSlip(response.data.url);
    //     } else {
    //       setUrlPaymentSlip(paymentSlips[0].url);
    //     }
    //   } else if (paymentSlips.length >= 2) {
    //     if (paymentSlips[0].guid !== null) {
    //       const response = getAzureDocuments(
    //         paymentSlips[paymentSlips.length - 1].guid
    //       );
    //       setUrlPaymentSlip(response.data.url);
    //     } else {
    //       setUrlPaymentSlip(paymentSlips[paymentSlips.length - 1].url);
    //     }
    //   }
    // }
  }, [documents]);

  const requestValueReview = async (desc) => {
    setLoading(true);
    let data = {
      name: RetrieveUserInformation().name,
      email: RetrieveUserInformation().email,
      processNumber: props.processNumber,
      description: desc,
    };

    await requestValeuReview(data)
      .then((r) => {
        setLoading(false);
        setResponseMessage("Solicitação de revisão de valores enviado.");
        setShow({...show, response: true, request: false});
      })
      .catch((e) => {
        setLoading(false);
        setResponseMessage(
          "Não foi possível solicitar a revisão. Entre em contato com os administradores.",
        );
        setShow({...show, response: true, request: false});
      });
  };

  const setLink = (link) => {
    props.setLink(link);
    setResponseMessage("O envio do comprovante foi concluído.");
    setTimeout(() => {
      setShow({...show, response: true, upload: false});
    }, 500);
  };

  const changeAddionalReference = (adReference) => {
    setShow({...show, additionalReference: false});
    props.setAdditionalReference(adReference);
  };

  const showDownload = () => {
    if (
      financialData.isPaymentSlip ||
      moment(financialData.date).format("DD/MM/YYYY") ===
        moment().format("DD/MM/YYYY") ||
      (moment(financialData.date).format("DD/MM/YYYY") ===
        moment().subtract(1, "days").format("DD/MM/YYYY") &&
        moment().format("hh") < 13)
    )
      return true;
    return false;
  };

  const disableEdit = () => {
    const analyst = RetrieveUserInformation().roles.includes("Analyst");
    const admin = RetrieveUserInformation().roles.includes(
      "CompanyAdministrator",
    );

    if (!analyst && !admin) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Backdrop className="loading" open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container className="financial-item">
        <Grid item md={1} style={{paddingLeft: 16}}>
          {RetrieveUserInformation().roles.includes("CompanyAdministrator") ||
          RetrieveUserInformation().roles.includes("Analyst") ? (
            <Tooltip
              title={
                available ? "Fatura aprovada? Sim" : "Fatura aprovada? Não"
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={available}
                    name="Status"
                    disabled={disableEdit()}
                    onChange={() => {
                      setAvailable(!available);
                      financialData.isAvailable = !financialData.isAvailable;
                      props.onChange("isAvailable");
                    }}
                  />
                }
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={
                checked ? "Fatura conferida? Sim" : "Fatura conferida? Não"
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    name="Status"
                    onChange={() => {
                      setChecked(!checked);
                      financialData.isChecked = !financialData.isChecked;
                      props.onChange("isChecked");
                    }}
                  />
                }
              />
            </Tooltip>
          )}
        </Grid>

        <Tooltip title={"Ref. Inicial: " + refItem}>
          <Grid item md={3} className="text">
            {adRefItem && adRefItem !== "" ? adRefItem : refItem}
          </Grid>
        </Tooltip>

        <Grid item md={2} className="text">
          {moment.utc(financialData.due).format("DD/MM/YYYY")}
        </Grid>
        <Grid item md={2} className="text">
          {financialData.isPayed || props.status === "Concluído" ? (
            "PAGO"
          ) : financialData.fileUpload ? (
            "EM ANÁLISE"
          ) : (
            <b>EM ABERTO</b>
          )}
        </Grid>
        <Grid item md={2} className="text">
          <NumberFormat
            displayType={"text"}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            value={financialData.value}
            fixedDecimalScale={true}
          />
        </Grid>
        <Grid
          item
          md={2}
          style={{textAlign: "center", cursor: "pointer", color: "#9fa9bc"}}
        >
          {showDownload() && (
            <Tooltip title="Download da fatura">
              <GetApp
                onClick={async () => await getDocumentInvoice(financialData)}
              />
            </Tooltip>
          )}
          {!showOnlyPayedItems && (
            <Tooltip title="Solicitar atualização de valores e/ou correções">
              <Refresh onClick={() => setShow({...show, request: true})} />
            </Tooltip>
          )}

          <Tooltip title="Anexar comprovante de pagamento">
            <Publish
              onClick={() => {
                setShow({...show, upload: true});
              }}
            />
          </Tooltip>
          {financialData.fileUpload && (
            <Tooltip title="Visualizar comprovante">
              {/* <Receipt onClick={() => setShow({ ...show, receipt: true })} /> */}
              <a
                href={financialData.fileUpload}
                target="_blank"
                download={"comprovante pagamento"}
                rel="noopener noreferrer"
              >
                {" "}
                <Receipt />
              </a>
            </Tooltip>
          )}
          {/* {propsItem.isPaymentSlip && urlPaymentSlip !== "" && ( */}
          <Tooltip title="Baixar boleto">
            <Payment
              onClick={async () => await getDocumentPayment(financialData)}
            />
            {/* <a
                href={urlPaymentSlip}
                target="_blank"
                download
                rel="noopener noreferrer"
              >
                <Payment />
              </a> */}
          </Tooltip>
          {/* )} */}
          <Tooltip title="Adicionar/Alterar a referência adicional">
            <LocalOffer
              onClick={() => setShow({...show, additionalReference: true})}
            />
          </Tooltip>
        </Grid>
      </Grid>

      {/* Modal de visualização da fatura */}
      <Modal
        open={show.preview}
        onClose={() => {
          setShow({...show, preview: false});
        }}
      >
        <div className="financial-modal-preview">
          <div className="financial-file">
            <PDFViewer pdf={{url: financialData.url}} />
          </div>
          <div className="container-buttons">
            <Button
              variant="contained"
              size="medium"
              onClick={() => setShow({...show, preview: false})}
            >
              Fechar
            </Button>
            <Link
              href={financialData.url}
              download
              style={{textDecoration: "none"}}
            >
              <Button
                variant="contained"
                size="medium"
                onClick={() => setShow({...show, preview: false})}
              >
                Baixar fatura
              </Button>
            </Link>
            {moment(financialData.date).format("DD/MM/YYYY") !==
              moment().format("DD/MM/YYYY") &&
              !financialData.isPaymentSlip && (
                <Button
                  className="primary"
                  variant="contained"
                  size="medium"
                  onClick={() => requestValueReview()}
                >
                  Solicitar revisão
                </Button>
              )}
          </div>
        </div>
      </Modal>

      {/* Modal de visualização do comprovante*/}
      <Modal
        open={show.receipt}
        onClose={() => {
          setShow({...show, receipt: false});
        }}
      >
        <div className="financial-modal-preview">
          <div className="financial-file">
            <PDFViewer pdf={{url: financialData.fileUpload}} />
          </div>
          <div className="container-buttons">
            <Button
              variant="contained"
              size="medium"
              onClick={() => setShow({...show, receipt: false})}
            >
              Fechar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal de resposta das solicitações */}
      <Modal
        open={show.response}
        onClose={() => {
          setShow({...show, response: false});
        }}
      >
        <div className="modalListUser">
          <div className="messageModalListUser">{responseMessage}</div>
          <div className="containerButtons">
            <Button
              className="cancelButtonListUser"
              variant="contained"
              size="medium"
              onClick={() => setShow({...show, response: false})}
            >
              Fechar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal para upload de arquivos */}
      <Modal
        open={show.upload}
        onClose={() => {
          setShow({...show, upload: false});
        }}
      >
        <div className="modalListUser">
          <div className="messageModalListUser" style={{paddingLeft: "30px"}}>
            <Uploader getLink={(e) => setLink(e)}></Uploader>
          </div>
        </div>
      </Modal>

      {/* Modal de inclusão/alteração de referência */}
      <Modal
        open={show.additionalReference}
        onClose={() => {
          setShow({...show, additionalReference: false});
        }}
      >
        <div className="modalListUser">
          <div className="messageModalListUser">
            <h5>Inclusão/Alteração da Referência Adicional</h5>
            <OutlinedInput
              autoComplete="off"
              placeholder="Referência adicional"
              value={additionalReference}
              onChange={(e) => {
                setAdditionalReference(e.target.value);
              }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="confirmar referência adicional"
                    edge="start"
                    style={{color: "#00B2AF"}}
                    onClick={() => changeAddionalReference(additionalReference)}
                  >
                    <Send />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </div>
      </Modal>

      {/* Solicitar revisão */}
      {show.request && (
        <RequestRevision
          requestValueReview={(e) => requestValueReview(e)}
          onClose={() => setShow({...show, request: false})}
          show={show.request}
          // eslint-disable-next-line eqeqeq
          title={adRefItem && adRefItem != "" ? adRefItem : refItem}
        />
      )}
    </>
  );
};

export default FinancialItem;
